.navbar {
  z-index: 100;
  width: 100%;
  height: 8vh;
  background: #fdfbfb;
  position: fixed;
  top: 0;
}

.navbar-link-container {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 1.1rem;
}

.navbar-link-container > .active {
  border-bottom: 2px solid coral;
}

a {
  color: black !important;
  text-decoration: none !important;
  cursor: pointer;
}

.page-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.section-container {
  width: 60%;
  height: 60vh;
}

@media (max-width: 1280px) {
  .burger svg {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 2rem;
    color: black !important;
  }

  .side-bar {
    z-index: 1000;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    top: 0;
    left: -100%;
    transition: 500ms;
  }

  .page-links {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 2rem;
  }

  .side-bar.active {
    background: rgb(206, 206, 206);
    left: 0;
    transition: 300ms;
  }

  svg {
    background: none;
    color: black !important;
  }
}

@media (max-width: 800px) {
  .section-container {
    width: 80%;
    height: 80vh;
  }
}
