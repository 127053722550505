.projects-section {
  width: 70%;
  height: 250vh;
  justify-content: baseline;
  padding-top: 8vh;
}

.projects-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-section-title {
  margin: 5vh 0;
}

.main-projects-container {
  margin-bottom: 8vh;
}

.project {
  width: 100%;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.carousel {
  width: 65%;
  border-radius: 10px;
}

.krypto .project-description, .bot .project-description {
  left: 62%;
}

.typey .carousel {
  float: right;
  width: 63%;
}

.project-description {
  overflow: auto;
  width: 40%;
  max-height: 30vh;
  background: rgb(255, 255, 255);
  padding: 3vh;
  position: absolute;
}

.project-description a, .project-description h3 {
  font-size: 2.5vh;
  padding: 3px 3px 3px 0;
  position: relative;
}

.project-description a:after {    
  background: none repeat scroll 0 0 transparent;
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(243, 150, 116);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.project-description a:hover:after { 
  width: 100%; 
  left: 0; 
}

.project-description p {
  font-size: 1.5vh;
  margin-bottom: 3px;
}

.side-projects-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.project-title {
  font-size: 1.5vh;
}

.side-project-description {
  overflow: auto;
  background: rgb(248, 248, 248);
  padding: 3vh;
  margin: 5px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 1.2vh;
}

.github-icon {
  width: 25px;
  height: 25px;
}

img {
  max-height: 100%;
  max-width: 100%;
}


@media (max-width: 800px) {
  .projects-section {
    height: 350vh;
  }
  
  .projects-section-title {
    font-size: 1.3rem;
  }

  .project {
    width: 100%;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel {
    width: 100% !important;
    position: static;
    margin-bottom: 2vh;
  }

  .project-description {
    width: 100% !important;
    position: static;
  }

  .carousel-indicators {
    display: none;
  }

  .side-projects-container {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
}
