.about-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 130vh;
}

.journey-container {
  height: 60%;
  display: flex;
  flex-direction: column;
}

.journey-title {
  font-size: 3rem;
  margin-bottom: 3rem;
}

.exp-header {
  font-weight: bold;
}

.exp-list {
  font-size: 14px;
}

@media (max-width: 800px) {
  .about-hobbies,
  .about-coding {
    width: 100%;
  }
}
