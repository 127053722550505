.home-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.intro-container {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.intro-name {
  font-size: 3rem;
  margin-bottom: 4vh;
}

.intro-paragraph {
  width: 50%;
}

.model-desc {
  font-size: 12px;
}

.model-container {
  position: absolute;
  height: 70%;
  width: 70%;
  top: 30%;
  left: 30%;
  z-index: 999;
}

canvas {
  height: 100%;
  width: 100%;
}

@media (max-width: 800px) {
  .intro-name {
    font-size: 1.7rem;
  }
  .intro-heading {
    font-size: 1.5rem;
  }
  .intro-paragraph {
    width: 100%;
  }

  .model-container {
    display: none;
  }
}
